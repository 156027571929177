import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import NavbarHeader from "./NavbarHeader"
import { faBarChart, faBars, faCross, faX, faXmark } from "@fortawesome/free-solid-svg-icons"
import { useState } from "react"
import React, { Component }  from 'react';
import './App.css'

function Navbar({
    menuBarActive,
    scrollFunction,
    gotoSection,
    section
}) {
    const [menuClicked, setMenuClicked] = useState(false)
    function openMenu () {
        setMenuClicked(prev => !prev)
    }

    return(
        <>
            {menuBarActive || menuClicked ? 
            <>
                <div style={{flex: '1', display: 'flex', alignItems: 'center'}}>
                    <div class="menuSelections" style={{fontSize: '32px', marginLeft: '20px', cursor: 'pointer', width: 'fit-content'}}>
                        {menuClicked ? null : <FontAwesomeIcon icon={faBars} onClick={openMenu}/>}
                    </div>
                </div>
                 <div class="mobileMenu" style={{height: '100vh', width: '100vw', backgroundColor: '#f8f8f8', zIndex: '50', position: 'fixed', display: 'flex', flexDirection: 'column', alignItems: 'center', left: menuClicked ? '0px' : '100vw'}}>
                    <div style={{height: '100%', width: '100%', position: 'relative'}}>
                        <div class="menuSelections" style={{fontSize: '32px', marginLeft: '20px', display: 'flex', alignItems: 'center', height: '64px', cursor: 'pointer', width: 'fit-content'}}>
                            {menuClicked ? <FontAwesomeIcon icon={faXmark} onClick={openMenu}/> : null}
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                            <div style={{borderBottom: '1.5px solid #808080',cursor: 'pointer'}}>
                                <div onClick={() => {gotoSection(1); setMenuClicked(false)}}class="menuSelections" style={{fontSize: '1.8em', padding: '1em'}} >
                                    Home
                                </div>
                            </div>
                            <div style={{borderBottom: '1.5px solid #808080',cursor: 'pointer'}}>
                                <div onClick={() => {gotoSection(2); setMenuClicked(false)}} class="menuSelections" style={{fontSize: '1.8em', padding: '1em'}}>
                                Academics
                                </div>
                                
                            </div>
                            <div style={{borderBottom: '1.5px solid #808080',cursor: 'pointer'}} >
                                <div onClick={() => {gotoSection(3); setMenuClicked(false)}} class="menuSelections" style={{fontSize: '1.8em', padding: '1em'}}>
                                    Experience
                                </div>
                            </div>
                            <div style={{borderBottom: '1.5px solid #808080',cursor: 'pointer'}}>
                                <div onClick={() => {gotoSection(4); setMenuClicked(false)}} class="menuSelections" style={{fontSize: '1.8em', padding: '1em'}}>
                                    Projects
                                </div>
                            </div>
                            <div style={{cursor: 'pointer'}}>
                                <div onClick={() => {gotoSection(5); setMenuClicked(false)}} class="menuSelections"  style={{fontSize: '1.8em', padding: '1em'}}>
                                    Contact
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                 </div>
            </>
            : 
            <div style={{flex: '1', display: 'flex', backgroundColor: '#fbfdfd'}}>
                <NavbarHeader title="Home" scrollFunction={() => scrollFunction(1)} highlighted = {section == 1}/>
                <NavbarHeader title="Academics" scrollFunction={() => scrollFunction(2)} highlighted = {section == 2}/>
                <NavbarHeader title="Experience" scrollFunction={() => scrollFunction(3)} highlighted = {section == 3}/>
                <NavbarHeader title="Projects" scrollFunction={() => scrollFunction(4)} highlighted = {section == 4}/>
                <NavbarHeader title="Contact" scrollFunction={() => scrollFunction(5)} highlighted = {section == 5}/>
            </div>}
        </>
    )
}

export default Navbar