import Lottie from "react-lottie"
import academic from "./lotties/academic.json"
import React, { Component, useEffect }  from 'react';
import './Academic.css'
import TreeEntry from "./TreeEntry";

function Academics({
    academicsSection,
    windowSize,
    isMobile,
    projectConfig
}) {

    return(
        <>
            {isMobile ? 
            
            <div id="academics-section" ref = {academicsSection} style={{width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                <h1 class="hidden" style={{fontSize: windowSize == 1 ? '24px' : '40px', fontWeight: '500', marginBottom: '5px',  borderRadius: '5px', padding: '10px'}}>
                    Academic <span style={{color: 'orange'}}>Background</span>
                </h1>
                <div class="hidden" style={{width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <div style={{width: '100%', aspectRatio: '1/1'}}>
                    <Lottie 
                        style={{ pointerEvents: 'none' }}
                        options={{loop: true,
                        autoplay: true,
                        animationData: academic,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }}}
                        margin={0} 
                    />
                    </div>
                </div>
                <div style={{width: '80%', fontSize: `max(1em, ${projectConfig.fontSize})`}} class="hidden">
                    <ul style={{ borderRadius: '5px', padding: '25px', overflow: 'auto', listStyleType: 'none'}}>
                        
                    <li style={{fontSize: '16px', fontWeight: '400'}}>
                    B.S. Computer Science and Mathematics at the University of Notre Dame
                    </li>
                    <li  style={{fontSize: '16px', fontWeight: '400'}}>
                    Selected Courses: Theory of Neural Networks, Distributed Systems, Operating Systems, Theory of Computing, Introduction to AI, Programming Paradigms, Partial Differential Equations, Probability, Operations Research, Linear Algebra, Ordinary Differential Equations
                    </li>
                    </ul>
                </div>
            </div>
            
            :
            //https://thecodeplayer.com/walkthrough/css3-family-tree
            
            <div id="academics-section" ref = {academicsSection} style={{width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '51vh', flexDirection: 'column', paddingTop: 'max(15vh, 50px)', paddingBottom: 'max(15vh, 50px)'}}>
                <h1 class="hidden" style={{fontSize: windowSize == 1 ? '24px' : '40px', fontWeight: '500', borderRadius: '5px', padding: '10px'}}>
                    Academic <span style={{color: 'orange'}}>Background</span>
                </h1>
                <div id="tree" class="tree" style={{marginLeft: '8%', marginRight: '8%', marginTop: 'max(50px, 10vh)'}}>
                    <ul>
                        <li>
                            <a class="header">B.S. Computer Science and Mathematics</a>
                            <ul>
                                <li>
                                    <a class="subheader">Mathematics</a>
                                    <ul>
                                        <li>
                                            <TreeEntry coursenumber="MATH 20550" description="A comprehensive treatment of differential and integral calculus of several variables. Topics include space curves, surfaces, functions of several variables, partial derivatives, multiple integrals, line integrals, surface integrals, Stokes theorem, and applications." coursename="Calculus III"/>
                                            <ul>
                                                <li>
                                                   <TreeEntry coursenumber="MATH 30530"  description="An introduction to the theory of probability, with applications to the physical sciences and engineering. Topics include discrete and continuous random variables, conditional probability and independent events, generating functions, special discrete and continuous random variables, laws of large numbers and the central limit theorem. The course emphasizes computations with the standard distributions of probability theory and classical applications of them." coursename="Probability"/>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <TreeEntry coursenumber="MATH 20630" description="This course serves as a transition to upper-level math courses. The general subject is numbers of all sorts-integers, rationals, reals, etc. The main point will be to treat everything the way a mathematician would. That is, we will give precise definitions of the objects we consider and careful statements of the assertions we make about them. And, most importantly, we will justify our assertions by giving mathematical proofs. Topics covered include basic language of sets, common methods of proof, integers, factorization, modular arithmetic, rational numbers, completeness, real numbers, cardinality, limits, and continuity." coursename="Introduction to Math Reasoning"/>
                                            <ul>
                                                <li>
                                                    <ul>
                                                        <li>
                                                            <TreeEntry className="willtake" description="The main goal of this course is to develop problem solving strategies in mathematics." coursenumber="MATH 43900" coursename="Problem Solving in Math"/>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <TreeEntry description="An introduction to vector spaces, matrices, linear transformations, inner products, determinants and eigenvalues. Emphasis is given to careful mathematical definitions and understanding the basic theorems of the subject." coursenumber="MATH 20610" coursename="Linear Algebra"/>
                                            <ul>
                                                <li>
                                                    <TreeEntry coursenumber="MATH 20750" description="An introduction to differential equations. Topics include first-order equations, n-th order linear equations, power series methods, systems of first order linear equations, non-linear systems and stability." coursename="Ordinary Differential Equations"/>
                                                    <ul>
                                                        <li>
                                                            <TreeEntry coursenumber="MATH 40750" description="An introduction to partial differential equations. Topics include Fourier series, solutions of boundary value problems for the heat equation, wave equation and Laplace's equation, Fourier transforms, and applications to solving heat, wave and Laplace's equations in unbounded domains." coursename="Partial Differential Equations" className="willtake"/>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <TreeEntry description="Operations research is the science (and art) of decision making. The success of a decision is frequently quantified through an achievement of certain goals (objectives) under restrictions imposed on various resources. Thus, mathematical models of operations research are frequently described as optimization problems, i.e. problems of minimization (maximization) of an objective function subject to various constraints. In this course we will consider optimization problems most frequently arising in various applications." coursenumber="MATH 30210" className="willtake" coursename="Operations Research"/>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a class="subheader">Computer Science</a>
                                    <ul>
                                        <li>
                                            <TreeEntry coursenumber="CSE 20311" description="This is the first part of a two-course computer programming sequence, intended primarily for computer science and computer engineering majors. It introduces fundamental concepts and principles of computer science, from formulating a problem and analyzing it conceptually, to designing, implementing, and testing a program on a computer. Using data and procedural abstractions as basic design principles for programs, students learn to define basic data structures, such as lists and trees, and to apply various algorithms for operating on them. The course also introduces object-oriented methods." coursename="Fundamentals of Computing"/>
                                            <ul>
                                                <li>
                                                    <TreeEntry coursenumber="CSE 20289" description="Systems Programming is a core Computer Science course that explores the fundamentals of computing systems. This course introduces students to the Unix programming environment where they will explore numerical representation, memory management, system calls, data structures, networking, and concurrency. Examining these topics will enable students to become familiar and comfortable with the lower level aspects of computing, while providing the foundation for further study in subsequent systems courses such as computer architecture and operating systems." coursename="Systems Programming"/>
                                                    <ul>
                                                        <li>
                                                            <TreeEntry coursenumber="CSE 30341" description="Introduction to all aspects of modern operating systems. Topics include process structure and synchronization, interprocess communication, memory management, file systems, security, I/O, and distributed files systems." coursename="Operating Systems"/>
                                                            <ul>
                                                                <li>
                                                                    <TreeEntry coursenumber="CSE 40771" description="This course will introduce students to the fundamental properties of distributed systems, and develop techniques for building systems that are reliable, consistent, and scalable. Topics will include remote procedure call, logging and checkpointing, replication, consistency, fault tolerance, security and privacy, and more. The course will include a substantial amount of programming to build several working distributed systems that implement scalable data storage, large scale computation, and reliable communication." coursename="Distributed Systems" className="willtake"/>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    <TreeEntry coursenumber="CSE 20312" description="This course deepens and broadens student exposure to imperative and object-oriented programming and data structures. Topics covered include modularity, specification, data abstraction, classes and objects, genericity, inheritance. This course will focus these topics on design and use of elementary data structures such as lists, stacks, queues, and trees, as well as advanced techniques such as divide-and-conquer, sorting, searching and graph algorithms. More advanced data structures such as priority queues and search trees will also be covered." coursename="Data Structures"/>
                                                    <ul>
                                                        <li>
                                                            <TreeEntry coursenumber="CSE 30332" description="Programming language overview: imperative and functional languages; logic programming. Scripting languages and tools. Development environments. Multilanguage interfacing. Case studies. Comprehensive programming practice using several languages." className="willtake" coursename="Programming Paradigms"/>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <TreeEntry coursenumber="CSE 20110" description="Introduction to mathematical techniques fundamental to computer engineering and computer science. Topics: mathematical logic, induction, set theory, relations, functions, recursion, recurrence relations, introduction to asymptotic analysis, algebraic structures, graphs, and machine computation." className="ta" coursename="Discrete Mathematics"/>
                                            <ul>
                                                <li>
                                                    <TreeEntry coursenumber="CSE 30151" description="Introduction to formal languages and automata, computability theory, and complexity theory with the goal of developing understanding of the power and limits of different computational models. Topics covered include: regular languages and finite automata; context-free grammars and pushdown automata; Turing machines; undecidable languages; the classes P and NP; NP completeness." coursename="Theory of Computing"/>
                                                    <ul>
                                                        <li>
                                                            <TreeEntry description="Introduction to the theory of neural networks: expressivity and trainability. Neural network architectures covered will include feed-forward, recurrent, convolutional and attention (transformer) neural networks.

This offering of the course will be focused on expressivity of neural networks for sequence data (language models), studying how they relate to theoretical models of computation like automata, Turing machines, logics, and Boolean circuits." coursenumber="CSE 60963" coursename="Theory of Neural Networks" className="willtake"/>
                                                            
                                                        </li>
                                                    </ul>
                                                    
                                                </li>
                                                <li>
                                                    <TreeEntry coursenumber="CSE 30124" description="Foundational concepts and techniques in AI and machine learning. Historical overview of the field. Search and logic programming. Canonical machine learning tasks and algorithms: supervised and unsupervised learning (classification and regression). Essential concepts from probability and statistics relevant to machine learning. Performance characterization. Modern software environments for machine learning and AI programming. Applications in unsupervised and supervised learning from image and textual data." coursename="Intro to Artificial Intelligence" className="willtake"/>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <TreeEntry coursenumber="CSE 20221" description="Boolean algebra and switching circuits, Karnaugh maps, design of combinational and of sequential logic networks, and sequential machines." coursename="Logic Design"/>
                                            <ul>
                                                <li>
                                                    <TreeEntry coursenumber="CSE 30321" description="Introduction to basic architectural concepts that are present in current scalar machines, together with an introduction to assembly language programming, computer arithmetic, and performance evaluation. Commercial computer-aided-design software is used to deepen the student's understanding of the top-down processor design methodology. MIPS-based assembly language will be used." coursename="Computer Architecture"/>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a class="subheader">Philosophy</a>
                                    <ul>
                                        <li>
                                            <TreeEntry coursenumber="PHIL 30329" description="Gateway course for the minor in Philosophy, Science, and Mathematics. Offered annually in the Fall semester, covering topics falling in the intersection between these three disciplines." coursename="Seminar in Philosophy, Science, and Math"/>
                                        </li>
                                        <li>
                                            <TreeEntry coursenumber="PHIL 20607" description="Philosophical exploration of the concept of infinity." coursename="Infinity"/>
                                            <ul>
                                                <li>
                                                    <TreeEntry coursenumber="PHIL 43906" description="A survey of central issues in the philosophy of mathematics. Topics include infinity, geometry, proof, computability, incompleteness, and set theory"coursename="Philosophy of Math"/>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="legend" style={{display: 'flex', flexDirection: 'column', width: '84%', alignItems: 'flex-end', fontWeight: '700', position: 'relative', bottom: '150px'}}>
                    <div class="hidden-right delay2">
                        <span style={{color: '#00bc6d'}}>Teaching Assistant</span><br></br>
                        <span style={{color: 'blueviolet'}}>Fall 2024</span>
                    </div>
                </div>
            </div>
            }
        </>
    )

}


export default Academics