import { useRef, useState, useEffect } from 'react' 

function TreeEntryDescription({
    condition,
    coursename,
    coursenumber,
    description,
    cursorX,
    cursorY,
    boundLeft,
    boundTop
}) {

    const myRef = useRef(null)
    const [adjustHeight, setAdjustHeight] = useState(0)
    const [adjustWidth, setAdjustWidth] = useState(0)
    const [refAssigned, setRefAssigned] = useState(false)
    const [startX, setStartX] = useState(0)
    const [startY, setStartY] = useState(0)

    useEffect(() => {
        if (myRef.current) {
            const height = myRef.current.clientHeight
            const width = myRef.current.clientWidth
            setAdjustWidth(width)
            setAdjustHeight(height)
            if (cursorX - 7 - adjustWidth < 0) {
                document.querySelectorAll('.tree *').forEach((element, index) => {
                    const zindex = 1
                    element.style.zIndex = zindex.toString()
                })
                let curr = myRef.current
                let i = 0
                while (!curr.classList.contains('tree')) {
                    const zindex = 20 - i
                    curr.style.zIndex = zindex
                    i += 1
                    curr = curr.parentNode
                }
                setStartX(-1 * boundLeft)
            } else {
                let count = 0
                for (let i = 0; i < 20; i++) {
                    document.querySelectorAll('.tree' + " > *".repeat(i+1)).forEach((element, index) => {
                        const zindex = i + 5 + count
                        element.style.zIndex = zindex.toString()
                        count += 1
                    })
                }
                setStartX(cursorX - adjustWidth - boundLeft - 7)
            }
            setStartY(cursorY - height - boundTop - 15)
        } 
    }, [refAssigned])

    useEffect(() => {
        if (myRef.current) {
            if (cursorX - 7 - adjustWidth < 0) {
                document.querySelectorAll('.tree *').forEach((element, index) => {
                    const zindex = 1
                    element.style.zIndex = zindex.toString()
                })
                let curr = myRef.current
                let i = 0
                while (!curr.classList.contains('tree')) {
                    const zindex = 20 - i
                    curr.style.zIndex = zindex
                    i += 1
                    curr = curr.parentNode
                }
                setStartX(-1 * boundLeft)
            } else {
                let count = 0
                for (let i = 0; i < 20; i++) {
                    document.querySelectorAll('.tree' + " > *".repeat(i+1)).forEach((element, index) => {
                        const zindex = i + 5 + count
                        element.style.zIndex = zindex.toString()
                        count += 1
                    })
                }
                setStartX(cursorX - adjustWidth - boundLeft - 7)
            }
            setStartY(cursorY - adjustHeight - boundTop - 15)
        }
    }, [cursorX, boundLeft, adjustWidth, adjustHeight, cursorY, boundTop])
 

    return (
        condition ? <div ref={node => {myRef.current = node; setRefAssigned(prev => !prev)}} className="classText box-width" style={{position: 'absolute', left: startX, top: startY, userSelect: 'none', backgroundColor: 'white', border: '2px solid black', padding: '10px', textAlign: 'left', fontSize: "max(1em, 12px)", boxShadow: '1px 1px 3px #666666'}}>
            <span className="classText" style={{color: 'black'}}>{coursename}</span>
            <br/>
            {coursenumber}
            <br/>
            <span className="classText" style={{color: 'black', fontWeight: '400'}}>{description}</span>
        </div> : null
    )
}

export default TreeEntryDescription