import Lottie from "react-lottie"
import phoneAnimationData from './lotties/PhonesAndStuff.json'
import React, { Component }  from 'react';
import './Experience.css'
import ExperienceSection from "./ExperienceSection";

function Experience({
    experienceSection,
    windowSize,
    isMobile,
    projectConfig
}) {

    return(
        <>
        {isMobile ? 
            
            <div id="experience-section" ref={experienceSection} style={{width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10vh', flexDirection: 'column'}}>
                <h1 class="hidden-right" style={{fontSize: windowSize == 1 ? '24px' : '40px', fontWeight: '500', marginBottom: '5px',  borderRadius: '5px', padding: '10px'}}>
                    Experience & <span style={{color: 'blueviolet'}}>Skills</span>
                </h1>
                <div class="hidden-right" style={{width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <div style={{width: '100%', aspectRatio: '1/1'}}>
                        <Lottie 
                        style={{ pointerEvents: 'none' }}
                        options={{loop: true,
                            autoplay: true,
                            animationData: phoneAnimationData,
                            rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                            }}}
                        margin={0}
                        />
                    </div>
                </div>
                <div class="hidden-right" style={{width: '80%', fontSize: `max(1em, ${projectConfig.fontSize})`}}>
                    <ul class="second-list" style={{borderRadius: '5px', padding: '25px', listStyleType: 'none'}}>
                        <li style={{fontSize: '16px', fontWeight: '400'}}>
                        Software Engineer Intern at Carrier - eCatalogs webapps, QA automation, and calculation bridges
                        </li>
                        <li style={{fontSize: '16px', fontWeight: '400'}}>
                        Software Engineer Intern at Desi - APIs, backend architecture, error functions, authentication &#40;<a style={{color: 'black'}}href="https://www.ridedesi.com" target="_blank">ridedesi.com</a>&#41;
                        </li>
                        <li style={{fontSize: '16px', fontWeight: '400'}}>
                        Teaching Assistant for CSE 20110 Discrete Mathematics
                        </li>
                        <li style={{fontSize: '16px', fontWeight: '400'}}>
                        3 summers at URochester TRIPODS REU researching neural networks
                        </li>
                    </ul>
                </div>
            </div>           
        
        :
            <div id="experience-section" ref={experienceSection} style={{width: '100vw', display: 'flex', alignItems: 'center', minHeight: '51vh', flexDirection: 'column', paddingTop: 'max(10vh, 50px)', marginBottom: 'max(25vh, 50px)'}}>
                <h1 style={{fontSize: windowSize == 1 ? '24px' : '40px', fontWeight: '500', marginBottom: '5px',  borderRadius: '5px', padding: '10px'}}>
                    Experience & <span style={{color: 'blueviolet'}}>Skills</span>
                </h1>
                <div class="experience-timeline" style={{flex: '1', width: 'min(95%, 1340px)', position: 'relative', display: 'flex', flexDirection: 'column'}}>
                    <ExperienceSection date="May 2024 - Present"logo="carrier.png" company="Carrier Corporation" title="Software Engineer Intern" description={["Built data pipelines to retrieve product drawings and convert them to viewable assets by utilizing S3 buckets in order to cache converted images and expand bottlenecks, predicting product asset retrievals and slashing avg. response times by 30%", "Proposed and built automated end-to-end regression test miner and suite with Cypress, jQuery, and Python, resulting in a 2.5x time reduction and a subsequent migration for the entire QA team to test for catalog defects in NA, Asia, and EMEA", "Introduced containerized tests into CI/CD pipeline using Docker and Jenkins as part of software development lifecycle", "Deployed a client-facing sales web app using React alongside Material UI and custom components for frontend & utilized internal framework to define backend dependencies for a specific product line which will service ~2000 customers/year"]} />
                    <ExperienceSection date="December 2023 - May 2024" flipped={true} links={[{desc: "https://apps.apple.com/us/app/desi-community-in-motion/id1667384111", title: "Rider"}, {desc: "https://apps.apple.com/us/app/desi-merchant/id1667384874", title: "Driver"}]}smoothen={true} logo="Desi.png" company="Yelo (Prev. Desi)" title="Software Engineer Intern" description={["Built dynamic ride handlers and refactored pre-existing endpoints with TypeScript through native integration with Firebase Cloud Functions resulting in 15% faster request response resolution times", "Introduced unit tests into team’s development workflow with Mocha to validate and test changes on updated features", "Led transition to new backend server by building tri-layer handler, service, and repository architecture in Golang and validated requests by handling I/O with middleware, error functions, & user authentication through JSON Web Tokens"]}/>
                    <ExperienceSection date="August 2023 - December 2023" logo="notredame.png" company="University of Notre Dame" title="CSE 20110 Teaching Assistant" description={["Graded homework and tests while providing detailed feedback for proofs on logic, set theory, and number theory", "Assisted 20-30 students a week by conducting office hours, resulting in a 4.9% higher final grade of active attendees"]}/>
                    <ExperienceSection date="May 2021 - September 2023" last={true} flipped={true} company="Rochester TRIPODS REU" title="Research Assistant" logo="universityofrochester.png" description={["Engineered artificial neural networks using TensorFlow and Pandas in Jupyter notebook to predict retail data trends using time-series data of 200 economic indicators, resulting in a 30-50% reduction in loss on highly correlated factors (Year 1)", "Developed Q-learning and Monte Carlo method-based models in the context of reinforcement learning. (Year 2)", "Implemented discrete neural networks utilizing groups and morphisms in universal algebra and category theory (Year 3)"]} />
                </div>
            </div>}
            
        </>
    )

}


export default Experience