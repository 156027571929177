import { useEffect, useRef, useState, useMemo } from 'react'
import './Academic.css'
import TreeEntryDescription from './TreeEntryDescription'

function TreeEntry({
    coursename,
    description,
    className,
    coursenumber
}) {

    const [cursorX, setCursorX] = useState(null)
    const [cursorY, setCursorY] = useState(null)

    const elementRef = useRef(null)

    const boundLeft = useMemo(() => elementRef.current ? elementRef.current.getBoundingClientRect().left : 0, [cursorX])
    const boundTop = useMemo(() => elementRef.current ? elementRef.current.getBoundingClientRect().top : 0, [cursorX])

    const mouseTracker = (e) => {
        setCursorX(e.clientX)
        setCursorY(e.clientY)
    }

    const startMouseTracker = () => {
        elementRef.current.addEventListener('mousemove', mouseTracker)
        elementRef.current.addEventListener('mouseleave', function remove() { 
            elementRef.current.removeEventListener('mousemove', mouseTracker) 
            elementRef.current.removeEventListener('mouseleave', remove)
            setCursorX(null)
            setCursorY(null)
        })
    }

    useEffect(() => {
        elementRef.current.addEventListener('mouseenter', startMouseTracker) 
    }, [])

    return(
        <>
            <a style={{position: 'relative'}}ref={elementRef} className={className}>
                {coursename}
                <TreeEntryDescription coursenumber={coursenumber} boundLeft={boundLeft} boundTop={boundTop} cursorX={cursorX} cursorY={cursorY} condition={cursorX != null} coursename={coursename} description={description}/>
            </a>
        </>
    )
}

export default TreeEntry