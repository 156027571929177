import './App.css'
import './Contact.css'
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Lottie from 'react-lottie';
import OnItsWay from './lotties/onitsway.json'


function Contact ({
    windowSize,
    reference
}) {

  const form = useRef();
  const [submitClicked, setSubmitClicked] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_o00k7fc', 'template_ab40l77', form.current, {
        publicKey: 'fBjIc8OVTQu-A0yfC',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  return (
    <div class="hidden" id="contact-section" ref={reference} style={{width: '100vw', justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', margin: '10vh 0px', marginBottom: '5vh'}}>
          <form class ="contact-form" ref={form} onSubmit={sendEmail}>  
            {submitClicked ? 
            <>
            <div style={{fontSize: '1.5em', textAlign: 'center'}}>
              Your message is on the way!
            </div>
            <Lottie 
            style={{ pointerEvents: 'none' }}
            options={{loop: true,
              autoplay: true,
              animationData: OnItsWay,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }}}
            margin={0}
            
            width = {'70%'}
            />
            </>
            :
            <>
              <div style={{fontSize: windowSize == 1 ? '24px' : '40px', fontWeight: '500', display: 'flex', justifyContent: 'flex-start', width: '100%', marginBottom: '0.5em'}}>
              Contact&nbsp;<span style={{color: 'blueviolet'}} value="Projects">Me</span>
              </div>
              <input name="first_name" type="text" class="feedback-input" placeholder="First Name" /> 
              <input name="first_name" type="text" class="feedback-input" placeholder="Last Name" />     
              <input name="email" type="text" class="feedback-input" placeholder="Email" />
              <textarea name="message" class="feedback-input feedback-input-textarea" placeholder="Inquiry or question..." style={{borderRadius: '5px'}}></textarea>
              <div onClick={() => {
                document.querySelector('.message-input').click()
                setSubmitClicked(true)
              }}>
                <input class="message-input" type="submit" value="Send"/>
              </div>
              
            </>
            }
            
            </form>
    </div>
  );
};

export default Contact;