import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

function ProjectSection({ githublink, flipped, project, description, skills, github, imagelink, projectConfig, isMobile, first }) {
    const descriptionRef = useRef(null); // Ref for description element

    return (
        <div class={flipped ? "hidden" : 'hidden-right'} style={{ flex: '1', display: 'flex', marginTop: first ? '3vh' : '10vh', fontSize: projectConfig.fontSize, width: 'min(80%, 1340px)' }}>
            {flipped ? 
                <>
                    <div style={{height: '100%', width: isMobile ? '100%' : '75%', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: isMobile ? '#fff' : 'white', borderRadius: '5px', padding: '1rem', boxShadow: isMobile ? '1px 1px 3px #666666' : 'none'}}>
                        <img class="img-window" src={imagelink} style={{objectFit: 'cover', width: '100%', border: project=="Quantdome" ? "2px solid #d5d5d5" : "none" , borderRadius: '2px'}} />
                        {isMobile ? <div style={{width: '80%', padding: '1rem', fontSize: isMobile ? '0.7rem' : '0.5rem', position: 'relative'}}>
                            <h1 style={{ fontSize: '2em', fontWeight: '550' }}>
                                {project}
                            </h1>
                            <p style={{ marginTop: '1em', fontSize: '1em',   }}>
                                {description}
                            </p>
                            <p style={{ marginTop: '1em', fontSize: '1em' }}>
                                {skills}
                            </p>
                            {github ? <a class="uColorFont" href={githublink}><h2 style={{fontSize: '2em', marginTop: '1em', marginLeft: '5px'}}>
                                <FontAwesomeIcon icon={faGithub}/>
                            </h2></a> : null}
                        </div> : null}
                    </div>
                    {isMobile ? null : <div style={{ width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div class="description-window" ref={descriptionRef} style={{ width: '100%', position: 'relative', right: '20%', padding: '5%', boxSizing: 'border-box', backgroundColor: '#fff', borderRadius: '5px', boxShadow: '1px 1px 3px #666666' }}>
                            <h1 style={{ fontSize: '2em', fontWeight: '550' }}>
                                {project}
                            </h1>
                            <p style={{ marginTop: '1em', fontSize: '1em',   }}>
                                {description}
                            </p>
                            <p style={{ marginTop: '1em', fontSize: '1em' }}>
                                {skills}
                            </p>
                            {github ? <a class="uColorFont" href={githublink} target="_blank"><h2 style={{fontSize: '1.5em', marginTop: '0.5rem'}}>
                                <FontAwesomeIcon icon={faGithub}/>
                            </h2></a> : null}
                        </div>
                    </div>}
                </>
                :
                <>
                    {isMobile ? null : <div style={{ width: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                        <div style={{position: 'absolute', width: '100%', left: '12%', padding: '8%', height: '550px', borderRadius: '3px', zIndex: '1'}}>

                        </div>
                        <div class="description-window" style={{ width: '100%', position: 'relative', left: '20%', padding: '5%', backgroundColor: '#fff', borderRadius: '5px', zIndex: '6', backgroundClip: 'padding-box', boxShadow: '-1px 1px 3px #666666' }}>
                            <h1 style={{ fontSize: '2em', fontWeight: '550' }}>
                                {project}
                            </h1>
                            <p style={{ marginTop: '1em', fontSize: '1em',  }}>
                                {description}
                            </p>
                            <p style={{ marginTop: '1em', fontSize: '1em' }}>
                                {skills}
                            </p>
                            {github ? <a class="uColorFont" href={githublink}><h2 style={{fontSize: '1.5em', marginTop: '0.5rem'}}>
                                <FontAwesomeIcon icon={faGithub}/>
                            </h2></a> : null}
                        </div>
                        
                    </div>}
                    <div style={{position: 'relative', zIndex: '1', height: '100%', width: isMobile ? '100%' : '75%', display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: isMobile ? '#fff' : 'white', borderRadius: '5px', padding: '1rem', boxShadow: isMobile ? '1px 1px 3px #666666' : 'none'}}>
                        <img class="img-window" src={imagelink} style={{objectFit: 'cover', width: '100%', borderRadius: '2px', position: 'relative', zIndex: '2'}} />
                        {isMobile ? <div style={{width: '80%', padding: '1rem', fontSize: isMobile ? '0.7rem' : '0.5rem', position: 'relative', borderRadius: '2px'}}>
                            <h1 style={{ fontSize: '2em', fontWeight: '550' }}>
                                {project}
                            </h1>
                            <p style={{ marginTop: '1em', fontSize: '1em',   }}>
                                {description}
                            </p>
                            <p style={{ marginTop: '1em', fontSize: '1em' }}>
                                {skills}
                            </p>
                            {github ? <a class="uColorFont" href={githublink} target="_blank"><h2 style={{fontSize: '2em', marginTop: '1em', marginLeft: '5px'}}>
                                <FontAwesomeIcon icon={faGithub}/>
                            </h2></a> : null}
                        </div> : null }
                        
                    </div>
                </>
            }
        </div>
    );
}

export default ProjectSection;
