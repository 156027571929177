import React, { Component }  from 'react';

function NavbarHeader({
    title,
    scrollFunction,
    highlighted
}) {

    return(
        <div class= {highlighted ? "uColor-focused" : "uColor"} onClick={() => scrollFunction()} style={{height: '64px', paddingRight: '25px', marginLeft: '5px', paddingLeft: '25px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: '500', cursor: 'pointer', boxSizing: 'border-box'}}>
            {title}
        </div>
    )

}

export default NavbarHeader