

export default function ExperienceSection({
    logo,
    company,
    title,
    description,
    flipped,
    smoothen,
    links,
    last,
    date
}) {
    return (
        <div style={{width: '100%', position: 'relative', height: 'fit-content', paddingBottom: last ? '0px' : '50px', display: 'flex'}}>
            {flipped ? 
            <div class="hidden delay-ind" style={{flex: '1', display: 'flex', alignItems: 'center', position: 'absolute', justifyContent: 'flex-end', height: 'calc(100% - 50px)', boxSizing: 'content-box', width: '50%', left: '-50px'}}>
                <div style={{marginLeft: '100px', fontSize: '1.5em', fontWeight: 'bold'}}>
                    {date}
                </div>
            </div>
             : null}
            <div class= {flipped ? "experience-unit-flipped experience-unit-flipped-pre" : "experience-unit experience-unit-pre"} style={{position: 'relative', width: 'calc(50% - 60px)', left: flipped ? 'calc(50% + 60px)': '0%', borderTop: '5px solid blueviolet', boxSizing: 'border-box', backgroundColor: '#fff', borderRadius: '5px', padding: '25px', boxShadow: '1px 1px 3px #666666', height: 'auto'}}>
                <img style={{width: '40%', borderRadius: smoothen ? '20px' : '0px'}}src={logo}/>
                <h1 style={{margin: '0.5em 0em', fontSize: '1.5em'}}>
                    {company}
                </h1>
                <h3 style={{fontSize: '1em'}}>
                    {title}
                </h3>
                <p style={{marginTop: '1em', fontSize: '0.85em'}}>
                    {description && description.map((text) => {
                        return(
                            <>
                            {text}
                            <br/>
                            <br/>
                            </>
                        )
                    })}
                </p>
                {links && links.map((link) => 
                    <>
                        <a style={{textDecoration: 'none', color: 'blueviolet'}}href={link.desc}>{link.title}</a>
                        <br/>
                    </>
                )}
            </div>
            <div class={flipped ? "experience-connector" : "experience-connector-flipped"}>

            </div>
            {flipped ? 
            
            null : 
            
            <div class="hidden-right delay-ind" style={{flex: '1', display: 'flex', alignItems: 'center'}}>
                <div style={{marginLeft: '100px', fontSize: '1.5em', fontWeight: 'bold'}}>
                    {date}
                </div>
            </div>}
        </div>
    )
}

