import './App.css';
import Lottie from 'react-lottie';
import animationData from './lotties/Terminal.json';
import phoneAnimationData from './lotties/PhonesAndStuff.json'
import academic from './lotties/academic.json'
import '@fortawesome/fontawesome-svg-core';
import {faEnvelope} from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/free-regular-svg-icons';
import '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavbarHeader from './NavbarHeader';
import { faGit, faGithub, faLinkedin, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import Project from './Project';
import { useEffect, useState, useRef } from 'react';
import Navbar from './Navbar';
import Contact from './Contact';
import ProjectSection from './ProjectSection';
import Experience from './Experience';
import Academics from './Academics';
import { throttle } from 'lodash'





export default function App() {

  const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
  
  const [windowSize, setWindowSize] = useState(1)
  const [mobile, setMobile] = useState(false)
  const [sectionOn, setSectionOn] = useState(0)
  
  function checkForResize() {
    if (window.innerWidth < 800) {
        setWindowSize(1)
    } else if (window.innerWidth < 1100) {
        setWindowSize(1.5)
    } else if (window.innerWidth < 1344) {
        setWindowSize(1.8)
    } else if (window.innerWidth < 1900) {
        setWindowSize(2)
    } else if (window.innerWidth < 2250) {
        setWindowSize(3)
    } else {
        setWindowSize(4)
    }
    if (window.innerWidth < 960) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }

  useEffect(() => {
    checkForResize()
  }, [])

  window.addEventListener("resize", checkForResize)

  const config = {
    fontSize: windowSize == 1 ? '48px' : '60px',
    flexDirection: windowSize == 1 ? "column" : "row",
    menuBarShown: windowSize == 1 ? true : false,
  }

  const projectConfig = {
    fontSize: windowSize == 4 ? '1.32em' : (windowSize == 3 ? '1.1em' : (windowSize == 2 ? '0.77em' : (windowSize == 1.8 ? '0.5em' : (windowSize == 1.5 ? '0.5em' : (windowSize == 1 ? '0.25em' : '0.6em'))))),
  }

  const projectConfigCustom = {
    fontSize: windowSize >= 2 ? '0.77em' : (windowSize == 1.8 ? '0.5em' : (windowSize == 1.5 ? '0.5em' : (windowSize == 1 ? '0.25em' : '0.6em')))
  }

  const homeSection = useRef(null);
  const academicsSection = useRef(null);
  const experienceSection = useRef(null);
  const projectsSection = useRef(null);
  const contactSection = useRef(null);
  
  
  const gotoSection = (num) => {
    if (num == 1) {
        homeSection.current?.scrollIntoView({behavior: 'smooth'})
    } else if (num == 2) {
        academicsSection.current?.scrollIntoView({behavior: 'smooth'})
    } else if (num == 3) {
        experienceSection.current?.scrollIntoView({behavior: 'smooth'})
    } else if (num == 4) {
        projectsSection.current?.scrollIntoView({behavior: 'smooth'})
    } else {
        contactSection.current?.scrollIntoView({behavior: 'smooth'})
    }
  }

  useEffect(() => {

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target.classList.contains('experience-unit')) {
            entry.target.classList.add('experience-unit-anim')
          } if (entry.target.classList.contains('experience-unit-flipped')) {
            entry.target.classList.add('experience-unit-flipped-anim')
          } else if (!entry.target.classList.contains('delay2') && !entry.target.classList.contains('delay-ind')) {
            entry.target.classList.add("shown")
          }
        }
      })
    });

    const hiddenElements = document.querySelectorAll('.hidden')
    hiddenElements.forEach((el) => observer.observe(el))
    const hiddenElements2 = document.querySelectorAll('.hidden-right')
    hiddenElements2.forEach((el) => observer.observe(el))
    const experienceElements = document.querySelectorAll('.experience-unit')
    experienceElements.forEach((el) => observer.observe(el))
    const experienceElements2 = document.querySelectorAll('.experience-unit-flipped')
    experienceElements2.forEach((el) => observer.observe(el))

    const treeObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          let count = 0
          for (let i = 0; i < 20; i++) {
            setTimeout(() => {
              document.querySelectorAll('.tree' + " > *".repeat(i+1)).forEach((element, index) => {
                element.setAttribute('id', 'shown2')
                const zindex = i + 5 + count
                element.style.zIndex = zindex.toString()
                count += 1
              })
            }, 500 + i * 100)
          }
          const twoSecDelayed = document.querySelectorAll('.delay2')
          setTimeout(() => {
            twoSecDelayed.forEach((ele) => {
              ele.classList.add('shown')
            })
          }, 2000)
        }
      })
    })
    const hiddenElements3 = document.querySelector('.tree')
    if (hiddenElements3) {
      treeObserver.observe(hiddenElements3)
    }


  }, [mobile])

  const delayIndObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => { 
      if (entry.isIntersecting) {
        setTimeout(() => {
          entry.target.classList.add('shown')
        }, 600)
      }
   }
  )})

  const delayIndElements = document.querySelectorAll('.delay-ind')
  delayIndElements.forEach((el) => delayIndObserver.observe(el))

  const [refAcquired, setRefAcquired] = useState(false)

  const sections = [homeSection, academicsSection, experienceSection, projectsSection, contactSection]

  const sectionHeaderMap = {
    "home-section": 1,
    "academics-section": 2,
    "experience-section": 3,
    "project-section": 4,
    "contact-section": 5,
  }

  function calculateCurrentSection(sections) {
      sections.forEach((section) => {
          const sectionRect = section.current.getBoundingClientRect()
          const windowHeight = window.innerHeight
          if (sectionRect.top <= windowHeight/2 && sectionRect.bottom >= windowHeight/2) {
            setSectionOn(sectionHeaderMap[section.current.id])
          }
          if (contactSection.current) {
            const contactRect = contactSection.current.getBoundingClientRect()
            const contactYUpper = Math.max(contactRect.top, 0)
            const contactYLower = Math.min(contactRect.bottom, windowHeight)
            if (contactYLower - contactYUpper > windowHeight / 2 || (contactRect.top - contactRect.bottom < windowHeight / 2 && contactRect.bottom < windowHeight && contactRect.top >= 0)) {
              setSectionOn(sectionHeaderMap[contactSection.current.id])
            }
          }
          if (homeSection.current) {
            const homeRect = homeSection.current.getBoundingClientRect()
            const homeYUpper = Math.max(homeRect.top, 0)
            const homeYLower = Math.min(homeRect.bottom, windowHeight)
            if (homeYLower - homeYUpper > windowHeight / 2 || (homeRect.top - homeRect.bottom < windowHeight / 2 && homeRect.bottom < windowHeight && homeRect.top >= -64)) {
              setSectionOn(sectionHeaderMap[homeSection.current.id])
            }
          }
      })
  }

  useEffect(() => {
    calculateCurrentSection(sections)
    window.addEventListener('scroll', throttle(() => calculateCurrentSection(sections), 300))
    return () => {window.removeEventListener('scroll', throttle(() => calculateCurrentSection(sections), 300))}

  }, [])

  return (
    <>
    <div style={{height: '64px', width: '100vw', display: 'flex', position: 'fixed', backgroundColor: '#fbfdfd', zIndex: '999'}}>
        <Navbar section={sectionOn} gotoSection={(num) => gotoSection(num)} menuBarActive={config.menuBarShown} scrollFunction={(num) => gotoSection(num)}/>
        <div style={{flex: '1', display: 'flex', justifyContent: 'flex-end', fontSize: '32px', alignItems: 'center', textDecoration: 'none', backgroundColor: '#fbfdfd'}}>
          <a class="uColorFont" href="https://github.com/RamenMode" target="_blank" style={{marginRight: '45px', textDecoration: 'none'}}>
            <FontAwesomeIcon icon={faGithub}/>
          </a>
          <a class="uColorFont" href="https://www.linkedin.com/in/kevin-xue-859133208/" target="_blank" style={{marginRight: '45px', textDecoration: 'none'}}>
            <FontAwesomeIcon icon={faLinkedinIn}/>
          </a>
        </div>
    </div>
    <div ref={homeSection} id="home-section" style={{width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: config.flexDirection, paddingTop: '144px', paddingBottom: '5vh'}}>
      <div style={{display: 'flex', alignItems: 'center', width: '60%', justifyContent: 'center'}}>
        <div class="hidden"style={{height: '350px', paddingLeft: windowSize == 1 ? '0' : '10vw', text: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'relative', bottom: windowSize == 1 ? '0px' : '50px'}}>
          <h1 style={{fontSize: config.fontSize, fontWeight: '600'}}>
            Hi, I'm <span style={{color: 'blueviolet'}}>Kevin Xue</span>
          </h1>
          <br/>
          <br/>
          <p style={{fontWeight: '500', fontSize: `max(1em, ${projectConfig.fontSize})`}}>
          I'm a rising Junior at the University of Notre Dame studying computer science and math with tangential interests in the philosophy of math, entrepreneurship, and AI.
          </p>
          <br/>
          <h3 style={{fontWeight: '500'}}>
            <div style={{display: 'flex', width: '100%'}}>
                          <a href="https://docs.google.com/document/d/1lqAcWiW5fYO1VkT1AObyci5Js00vtQGZ/edit?usp=sharing&ouid=115400267950471475058&rtpof=true&sd=true" target="_blank" style={{textDecoration: 'none'}}>
                              <div className = "download-resume" >
                                  <p style={{textDecoration: 'none', color: 'black'}}>View Resume <span style={{border: '2px solid black', padding: '10px 15px', borderRadius: '500px', backgroundColor: 'white', color: 'black'}}><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon></span></p>
                              </div>
                          </a>
                      </div>
          </h3>
        </div>
      </div>
      <div style={{width: 'max(40%, 300px)', position: 'relative', bottom: windowSize == 1 ? '-40px' : '50px', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingRight: '5vw'}}>
        <Lottie 
          style={{ pointerEvents: 'none' }}
          options={defaultOptions}
        />
      </div>
    </div>
    <Academics academicsSection={academicsSection} windowSize={windowSize} isMobile={mobile} projectConfig={projectConfig}/>
    <Experience experienceSection={experienceSection} windowSize={windowSize} isMobile={mobile} projectConfig={projectConfig}/>
    <div ref={projectsSection} id="project-section" style={{width: '100vw', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', paddingTop: '40px'}}>
      <h1 class="hidden" style={{fontSize: windowSize == 1 ? '24px' : '40px', fontWeight: '500', marginBottom: '5px',  borderRadius: '5px', padding: '10px'}}>
          My <span style={{color: 'orange'}}>Projects</span>
        </h1>
      <ProjectSection first={true} isMobile={mobile} projectConfig={projectConfigCustom} imagelink="/boeing.png" githublink = "https://github.com/RamenMode/Boeing_Innovation_Project_Sterile" github={true} flipped={false} project={"Boeing Aircraft Maintenance Solution"} description={"Led and developed an end-to-end 95% accurate NLP solution integrated with a web application and API to classify maintenance reports for a data science team at Boeing"} skills={"Skills: React, Flask, Support Vector Machines, Universal Sentence Encoder, CSS"}/>
      <ProjectSection github={true} githublink={"https://github.com/RamenMode/csbulletin-links"} isMobile={mobile} projectConfig={projectConfigCustom} imagelink="/csbulletin.png" flipped={true} project="CSBulletin - CSGO Asset Trading Site" description="Constructed a trading web application for a video game that enables users to log in with cookies, offer trades on a marketplace, access a database of all items, create listings with inventory retrieval, and manage listings." skills="Skills: Node.js, React, CSS, REST, Express, MongoDB, Steam API, Passport.js"/>
      <ProjectSection github={true} githublink={"https://github.com/RamenMode/buffbot"} isMobile={mobile} projectConfig={projectConfigCustom} imagelink="/buff.png" flipped={false} project="Buff163 - CSGO Market Bot" description="Automated the purchase of assets on a trading site by implementing a “sniping” feature for filtered items. Developed further iterations of bot for clients by utilizing asyncio and reverse-engineered APIs to increase efficiency." skills="Skills: Python, ChromeDriver, Selenium, asyncio, multithreading"/>
      <ProjectSection isMobile={mobile} projectConfig={projectConfigCustom} imagelink="/quantdome.png" github={false} flipped={true} project="Quantdome" description="Helped develop data handlers for Quantdome, a ND Financial Engineering Club trading software with microservices event-driven architecture using Apache Kafka. The software allows for real-time and backtesting simulation of strategies. Presented final product to International Business Council President Matt Bush" skills="Skills: Apache Kafka, Python, Microservices"/>
    </div>
    <Contact windowSize={windowSize} reference={contactSection}/>
    <div style={{width: '100vw', display: 'flex', justifyContent: 'center'}}>
      <hr style={{width: '90%'}}/>
    </div>
        <footer style={{height: '100%', width: '90vw', display: 'flex', flexDirection: config.flexDirection, fontSize: '14px', margin: '32px'}}>

          <div style={{flex: '1', height: '100%', display: 'flex', marginLeft: '32px', justifyContent: 'center', flexDirection: 'column'}}>
            <a class="uColorFont" href="https://www.kevxue.com" style={{textDecoration: 'none'}}><h1 style={{fontSize: '2em', marginBottom: '1em', fontWeight: '500', width: 'fit-content', cursor: 'pointer'}}>kevxue.com</h1></a>
            <p style={{color: '#b9b9b9', fontSize: '1em',  marginBottom: '1em',}}>&#169;Kevin Xue 2024. All Rights Reserved.</p>
            <p style={{fontSize: '3em'}}><a class="uColorFont" href="https://github.com/RamenMode" style={{textDecoration: 'none'}} target="_blank"><FontAwesomeIcon icon={faGithub}/></a>&#160;<a class="uColorFont" href="https://www.linkedin.com/in/kevin-xue-859133208" style={{textDecoration: 'none'}} target="_blank"><FontAwesomeIcon icon={faLinkedinIn}/></a></p>
          </div>
          <div style={{flex: '1', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: windowSize > 1 ? 'flex-end': 'flex-start', marginRight: '10vw', marginLeft: windowSize == 1 ? "32px" : '0px', marginTop: windowSize == 1 ? "0.5em" : "0em"}}>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
              <a class="uColor" onClick={() => gotoSection(1)} style={{fontSize: '1.2em', marginBottom: '0.5em', fontWeight: '400', cursor: 'pointer', width: 'fit-content'}}>
                    Home
              </a>
              <a class="uColor" onClick={() => gotoSection(2)} style={{fontSize: '1.2em', marginBottom: '0.5em', fontWeight: '400', cursor: 'pointer', width: 'fit-content'}}>
                  Academics
              </a>
              <a class="uColor" onClick={() => gotoSection(3)} style={{fontSize: '1.2em', marginBottom: '0.5em', fontWeight: '400', cursor: 'pointer', width: 'fit-content'}}>
                  Experience
              </a>
              <a class="uColor" onClick={() => gotoSection(4)} style={{fontSize: '1.2em', marginBottom: '0.5em', fontWeight: '400', cursor: 'pointer', width: 'fit-content'}}>
                  Projects
              </a>
              <a class="uColor" onClick={() => gotoSection(5)} style={{fontSize: '1.2em', marginBottom: '0.5em', fontWeight: '400', cursor: 'pointer', width: 'fit-content'}}>
                  Contact Me
              </a>
            </div>
          </div>
        </footer>
    </> 
  );
}

